import { QUEUE_STATS_LOADED } from '../actions/config';
export default function (state = null, action) {
    switch (action.type) {
        case QUEUE_STATS_LOADED:
            const result = [];
            const { start, end } = action.payload.query.hour_range;

            for (let hour = start; hour <= end; hour++) {
                const data = { hour, stats: [] };
                for (let i = 0; i < action.payload.data.length; i++) {
                    const row = action.payload.data[i];
                    data.stats.push(
                        {
                            date: row.Date,
                            answered: row.Answered["_" + hour] || 0,
                            unanswered: row.UnAnswered["_" + hour] || 0,
                        }
                    )
                }
                result.push(data);
            }

            return { query: action.payload.query, result };
        default:
            return state;
    }
}
