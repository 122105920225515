import React, { Component } from 'react';
import { CardBody, Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';
import Select from 'react-select';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import { createUser, updateUser } from '../../../actions/users';
import { listExtensions } from '../../../actions/configurations';

class CreateUser extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    componentWillMount() {
        this.init();
    }

    componentDidUpdate() {
        if (this.props.user.login_id && this.state.login_id !== this.props.user.login_id) {
            this.init();
        }
    }

    init() {
        const { groups, roles, own } = this.props;

        this.setState({
            ...this.state,
            ...this.props.user
        })

        if (!own) {
            this.setState({
                group: _.find(groups, { group_id: this.props.user.group_id }),
                role: _.find(roles, { role_id: this.props.user.login_role_id })
            });
        }
    }

    clearForm() {
        this.props.onClose();
    }

    saveData(e) {
        e.preventDefault();

        const self = this;
        const editData = {
            login_id: this.state.login_id,
            login_role_id: this.state.role.role_id,
            work_group_id: this.state.group.group_id,
            extension_id: this.state.extension_id
        }
        this.props.updateUser(editData, (err) => {
            if (!err) {
                self.clearForm();
            }
        });
    }


    render() {

        const { roles, groups, own } = this.props;

        return (
            <form onSubmit={(e) => this.saveData(e)} >
                <CardBody>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label htmlFor="first_name">First Name</Label>
                                <Input readOnly type="text" value={this.state.first_name} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label htmlFor="last_name">Last Name</Label>
                                <Input type="text" value={this.state.last_name} readOnly />
                            </FormGroup>
                        </Col>
                    </Row>
                    {!this.props.own && <div>
                        <Row>
                            <Col xs="12">
                                <FormGroup>
                                    <Label htmlFor="login_username">Username</Label>
                                    <Input readOnly type="text" value={this.state.login_username} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <FormGroup>
                                    <Label htmlFor="login_username">Extension</Label>
                                    <Input type="text" readOnly value={this.state.extension} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <FormGroup>
                                    <Label htmlFor="role">Role</Label>
                                    <Select
                                        name="form-field-name2"
                                        value={this.state.role}
                                        options={roles}
                                        onChange={(e) => this.setState({ role: e })}
                                        getOptionValue={option => option['role_id']}
                                        getOptionLabel={option => option['name']}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <FormGroup>
                                    <Label htmlFor="name">Group</Label>
                                    <Select
                                        name="form-field-name2"
                                        value={this.state.group}
                                        options={groups}
                                        onChange={(e) => this.setState({ group: e })}
                                        getOptionValue={option => option['group_id']}
                                        getOptionLabel={option => option['group_name']}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>}{own ? "" :
                        <Row>
                            <Col className="text-right">
                                <Button type="submit" color="primary">Save</Button>{' '}
                                <Button onClick={() => this.clearForm()} color="danger">Cancel</Button>
                            </Col>
                        </Row>}
                </CardBody>
            </form>
        );
    }
}

function mapStateToProps({ roles, groups }) {
    return {
        roles, groups
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        listExtensions,
        createUser,
        updateUser
    }, dispatch);
}

export default (connect(mapStateToProps, mapDispatchToProps)(CreateUser));
