export default {
    //2020: /^Ext\.[18]\d{3}$/,
    3170: /^Ext\.2\d{3}$/,
    3280: /^Ext\.2\d{3}$/,
    3230: /^Ext\.2\d{3}$/,
    3120: /^Ext\.2\d{3}$/,
    3040: /^Ext\.2\d{3}$/,
    3070: /^Ext\.2\d{3}$/,
    3270: /^Ext\.2\d{3}$/,
    3200: /^Ext\.2\d{3}$/,
    3220: /^Ext\.2\d{3}$/,
    3000: /^Ext\.[23]\d{3}$/,
    3010: /^Ext\.[23]\d{3}$/
};