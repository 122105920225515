import { CALL_LOGS_LOADED } from '../actions/config';
import call_types from '../config/call_types';
import call_permissions from '../config/call_permissions';

function findType(call) {
    const type = _.find(call_types, (a) => {
        let res = false;
        for (let i = 0; i < a.filter.length; i++) {
            const filter = a.filter[i];
            if (typeof filter.source === "undefined" || call.source_type === filter.source) {
                res = true;
            } else if (typeof filter.destination !== "undefined" || call.destination_type !== filter.destination) {
                res = true;
            }
        }
        return res;
    })

    if (type) {
        return type.label;
    } else {
        return "Other";
    }
}

export default function (state = null, action) {
    switch (action.type) {
        case CALL_LOGS_LOADED:
            const allowed_ext = call_permissions[action.extension];
            for (let i = 0; i < action.payload.length; i++) {
                const { destination_caller_id, source_caller_id } = action.payload[i];
                action.payload[i].id = i;
                action.payload[i].type = findType(action.payload[i]);
                if (allowed_ext && (destination_caller_id.match(allowed_ext) ||
                    source_caller_id.match(allowed_ext) ||
                    destination_caller_id === `Ext.${action.extension}` ||
                    source_caller_id === `Ext.${action.extension}`)) {
                    action.payload[i].hasAccess = true;
                }
            }
            return action.payload;
        default:
            return state;
    }
}
