//export const BASE_URL2 = process.env.REACT_APP_BASE_API;
//export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_URL = process.env.REACT_APP_BASE_URL;
export const FEEDBACK_API = process.env.REACT_APP_FEEDBACK_API;
export const NOTIFICATION_REFRESH_INTERVAL = process.env.REACT_APP_NOTIFICATION_REFRESH_INTERVAL || 5;
export const DASHBOARD_REFRESH_INTERVAL = process.env.REACT_APP_DASHBOARD_REFRESH_INTERVAL || 5;
export const RECORDINGS_URL = process.env.REACT_APP_RECORDINGS_URL;
export const RECORDINGS_URL_BACKUP = process.env.REACT_APP_RECORDINGS_URL_BACKUP;
export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
export const LOCATION_TRACKER_LINK = process.env.REACT_APP_LOCATION_TRACKER_LINK;
export const PHONE_REGEX = /^((\+947|947|07|7)(0|1|2|4|5|6|7|8)[0-9]{7})$/;
export const DASHBOARD_GRAPH_TYPE = "Outbound";
export const MI_API_URL = process.env.MI_API_URL;
export const LINK_3CX_CONFIG = process.env.REACT_APP_LINK_3CX_CONFIG || "https://simsyn.my3cx.sg";
export const LINK_3CX_REPORTS = process.env.REACT_APP_LINK_3CX_REPORTS || "https://simsyn.my3cx.sg/#/app/call_reports/call_reports";
export const HANDLING_TIME_SEC = process.env.REACT_APP_HANDLING_TIME_SEC || 5;

