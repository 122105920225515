import { USER_LOGGED_IN, USER_LOGGED_OUT, USER_CONFIG_LOADED, GROUP_CONFIG_LOADED, PROFILE_UPDATED } from '../actions/config';

export default function (state = JSON.parse(localStorage.getItem('lbcc_user')), action) {
    switch (action.type) {
        case USER_LOGGED_IN:
            localStorage.setItem('lbcc_user', JSON.stringify(action.payload));
            return action.payload;
        case USER_LOGGED_OUT:
            localStorage.removeItem('lbcc_user');
            return null;
        case PROFILE_UPDATED:
            const newState = { ...state, ...{ user_details: action.payload } };
            localStorage.setItem('lbcc_user', JSON.stringify(newState));
            return newState;
        case USER_CONFIG_LOADED:
            state.user_config = action.payload
            return state;
        case GROUP_CONFIG_LOADED:
            state.group_config = action.payload
            return state;
        default:
            return state;
    }
}
