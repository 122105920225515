import _ from "lodash";
import { LINK_3CX_CONFIG, LINK_3CX_REPORTS } from "./globals";

const getItems = () => {
  let items = [];
  const userData = JSON.parse(localStorage.getItem("lbcc_user"));

  const checkPermission = (name) => {
    const perm = _.find(userData.login_rules, { module_name: name });
    if (perm) {
      if (perm.readaccess || perm.writeaccess) {
        return true;
      }
    }
    return false;
  }

  if (userData) {
    if (userData.login_rules.length > 0) {
      if (checkPermission("Dashboard")) {
        items.push({
          name: "Dashboards",
          icon: "fa fa-tachometer",
          children: [
            {
              name: "Call Distribution",
              url: "/dashboard",
              icon: "fa fa-line-chart",
            }, {
              name: "Call Statistics",
              url: "/call-stats",
              icon: "fa fa-pie-chart"
            }
          ]
        });
      }

      if (checkPermission("Campaigns Management")) {
        items.push({
          name: "Campaigns",
          icon: "fa fa-columns",
          url: "/campaigns"
        });
      }

      const leads = {
        name: "Leads",
        icon: "fa fa-phone-square",
        children: []
      };

      if (checkPermission("Facility Management")) {
        leads.children.push({
          name: "Lead Details",
          url: "/leads",
          icon: "fa fa-address-card-o",
        });
      }


      if (checkPermission("Followups Management")) {
        leads.children.push({
          name: "Follow Ups",
          url: "/followups",
          icon: "fa fa-bell",
        });
      }

      if (checkPermission("Disposition Management", "readaccess")) {
        leads.children.push({
          name: "Disposition Notes",
          url: "/disposition-notes",
          icon: "fa fa-phone-square",
        })
      }

      if (leads.children.length > 0) {
        items.push(leads);
      }

      if (checkPermission("Location Management")) {
        items.push({
          name: "Customer Locations",
          icon: "fa fa-map",
          children: [
            {
              name: "Location Tracker",
              url: "/location-tracker",
              icon: "fa fa-map-marker",
            }, {
              name: "Location List",
              url: "/location-history",
              icon: "fa fa-compass",
            }, {
              name: "Message History",
              url: "/message-history",
              icon: "fa fa-comments-o",
            }]
        });
      }

      if (checkPermission("Configurations")) {
        items.push({
          name: "Templates",
          icon: "fa fa-file-text-o",
          children: [
            {
              name: "Campaigns",
              url: "/templates",
              icon: "fa fa-columns",
            },
            {
              name: "SMS",
              url: "/sms-templates",
              icon: "fa fa-comment-o",
            },
            {
              name: "Email",
              url: "/email-templates",
              icon: "fa fa-envelope-o",
            },
            {
              name: "Follow Up Note",
              url: "/followup-templates",
              icon: "fa fa-bell-o",
            },
            {
              name: "Disposition Note",
              url: "/disposition-templates",
              icon: "fa fa-phone-square",
            },
          ],
        });
      }

      if (checkPermission("Configurations")) {
        items.push({
          name: "Configurations",
          icon: "fa fa-cog",
          children: [
            {
              name: "Agent",
              url: "/agent-configs",
              icon: "fa fa-user",
            },
            {
              name: "Group",
              url: "/group-configs",
              icon: "fa fa-group",
            },
            {
              name: "Agent Breaks",
              url: "/agent-break-config",
              icon: "fa fa-clock-o",
            },
            {
              name: "Field Layout",
              url: "/field-layout",
              icon: "fa fa-address-card-o",
            },
            {
              name: "3CX Configurations",
              url: LINK_3CX_CONFIG,
              attributes: { target: "_blank", external_link: true },
              icon: "fa fa-external-link",
            }
          ],
        });
      }

      if (checkPermission("Reports")) {
        items.push({
          name: "Reports",
          icon: "fa fa-line-chart",
          children: [
            {
              name: "3CX Standard Reports",
              url: LINK_3CX_REPORTS,
              attributes: { target: "_blank", external_link: true },
              icon: "fa fa-external-link",
            }, {
              name: "Performance Dashboard",
              url: "/agent-performance",
              icon: "fa fa-users",
              // }, {
              //   name: "KPI Analyzer",
              //   url: "/kpi-analyzer",
              //   icon: "fa fa-pie-chart",
            }, {
              name: "Etiquettes Rating",
              url: "/agent-etiquettes",
              icon: "fa fa-star-half-o",
            }, {
              name: "Activity Monitor",
              url: "/agent-monitor",
              icon: "fa fa-bell-o",
            }, {
              name: "Activity Reports",
              url: "/activity-report",
              icon: "fa fa-calendar",
            }, {
              name: "Activity Summary",
              url: "/agent-activity",
              icon: "fa fa-clock-o",
            }, {
              name: "Queue Statistics",
              url: "/queue-statistics",
              icon: "fa fa-volume-control-phone",
            }, {
              name: "Customer Feedback",
              url: "/customer-feedback",
              icon: "fa fa-comments",
            },
            {
              name: "Dialer Reports",
              url: "/dialer-reports",
              icon: "fa fa-phone",
            }, {
              name: "Outbound Call Counts",
              url: "/call-counts",
              icon: "fa fa-bar-chart",
            },
          ],
        });
      }

      if (checkPermission("User Management")) {
        items.push({
          name: "User Management",
          icon: "fa fa-users",
          children: [
            {
              name: "Users",
              url: "/users",
              icon: "fa fa-user",
            },
            {
              name: "Roles",
              url: "/roles",
              icon: "fa fa-key",
            },
            {
              name: "Groups",
              url: "/groups",
              icon: "fa fa-group",
            },
            {
              name: "Extensions",
              url: "/extensions",
              icon: "fa fa-cog",
            },
          ],
        });
      }

      if (checkPermission("Call Logs Management")) {
        items.push({
          name: "Call Logs",
          url: "/call-logs",
          icon: "fa fa-file-text-o",
        });
      }
    }
  }

  return { items };
}


export default { getItems };
