import React, { Component } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultFooter extends Component {
  render() {
    return (
      <React.Fragment>
        <span><a href="https://simsyn.com/">SimSyn Private Limited</a> &copy; 2021</span>
        <span className="ml-auto">Stable Release (01.00.00.21-12-19-1)</span>
        <span className="ml-auto">Support Hotline +94 117 687 587</span>
      </React.Fragment>
    );
  }
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;
