import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import { logout } from '../../actions/index';
import ProfileSettings from '../views/Users/ProfileSettings';
import history from '../../history';

class UserMenu extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false,
            modal: false,
            loggeduser: ''
        };

        this.toggleModel = this.toggleModel.bind(this);
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    }

    toggleModel() {
        this.setState({
            modal: !this.state.modal,
        });
    }

    componentWillMount() {
        if (JSON.parse(localStorage.getItem('lbcc_user'))) {
            this.setState({
                loggeduser: JSON.parse(localStorage.getItem('lbcc_user')).login_username
            });
        }
    }

    logout() {
        this.props.logout(() => {
            history.push("/");
        })
    }

    render() {
        const { user } = this.props;
        return (
            <div style={{ height: 21, marginLeft: 10 }}>
                <Dropdown nav className="d-md-down-none" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                    <DropdownToggle nav>
                        {this.state.loggeduser ? (<span ><i className="fa fa-user"></i> {user.user_details.first_name + ' ' + user.user_details.last_name}</span>) : (<span className="text-avatar bg-info">A</span>)}
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-lg" right>
                        <DropdownItem onClick={() => { this.setState({ modal: true }) }}><i className="fa fa-user"></i>Profile Settings</DropdownItem>
                        <DropdownItem onClick={() => this.logout()}><i className="fa fa-sign-out"></i> Logout</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                <ProfileSettings isOpen={this.state.modal} toggle={this.toggleModel} />
            </div>

        );
    }
}

function mapStateToProps({ user }) {
    return {
        user
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        logout
    }, dispatch);
}


export default (connect(mapStateToProps, mapDispatchToProps)(UserMenu));
